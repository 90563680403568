import $ from "jquery";
import { gsap, ScrollTrigger } from "gsap/all";
import { slick } from "slick-carousel";
gsap.registerPlugin(ScrollTrigger);

// Burger
(function burger() {
    const hamburger = $('.hamburger');
    const topNav = $('.top-nav');
    
    hamburger.on('click', function(){
        $(this).toggleClass('is-active');
        topNav.slideToggle(500);
    })
})();

// Header On Scroll
(function headerOnScroll(){
        const $windowWidthResize = $(window).width();
        if ($windowWidthResize >= 1280) {
            ScrollTrigger.create({
                start: 'top -100',
                end: 99999,
                toggleClass: {
                    className: 'scrolled',
                    targets: '.top-header'
                }
            });
        }
})();

// FadeUp on Scroll
(function fadeUp() {
    $('.fade-up').each(function(){
        gsap.to($(this), {
            ease: "none",
            scrollTrigger: {
              trigger: $(this),
              start: "top bottom",
              end: "bottom top",
              toggleActions: "play none none none",
            },
            opacity: 1,
            y: 0,
        });
    })
})();

// Hero Parallax
(function parallax() {
    // Img must have - height: calc(100% + 300px);
    $('.js-parallax').each(function(){
        gsap.to($(this), {
            top: -300,
            ease: "none",
            scrollTrigger: {
              trigger: $(this),
              start: "top bottom",
              end: "bottom top",
              scrub: true
            }
        });
    })
})();

// Customer opinions Slick Slider
(function opinionsSlider(){
    const opinionsSlider = $('.js-opinions');
    const opinionsSliderExist = opinionsSlider.length;

    if (opinionsSliderExist) {
        opinionsSlider.each(function(){
            const opinionsOptions = {
                infinite: true,
                autoplay: true,
                autoplaySpeed: 7000,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: 0,
                speed: 1000,
                mobileFirst: true,
                easing: 'ease-in-out',
                swipeToSlide: true,
                pauseOnFocus: true,
                variableWidth: true,
                appendArrows: $(this).parent().find('.opinions-nav'),
                prevArrow: '<button type="button" class="slick-prev text-gray-300 text-4xl mx-2 xl:hover:text-primary transition"><i class="fas fa-arrow-left"></i></button>',
                nextArrow: '<button type="button" class="slick-next text-gray-300 text-4xl mx-2 xl:hover:text-primary transition"><i class="fas fa-arrow-right"></i></button>',
            }
            $(this).slick(opinionsOptions);
        })
    }
})();

// Click event for any anchor tag that's href starts with #
(function scrollTo(){
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        // Select all links with hashes
        $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .on('click', function(event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
                && 
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000, function() {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                        return false;
                        } else {
                        $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                        $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });
    }
})();

// Sections Clipping
(function sectionClipping(){
    $('.site-content section').each(function(){
        const sectionClasses = $(this);
        if ( sectionClasses.hasClass('clip--top') ) {
            $(this).prev('section').addClass('clip--before');
        }
        if ( sectionClasses.hasClass('clip--top-bottom') ) {
            $(this).prev('section').addClass('clip--before');
            $(this).next('section').addClass('clip--after');
        }
        if ( sectionClasses.hasClass('clip--bottom') ) {
            $(this).next('section').addClass('clip--after');
        }
    })
})()
